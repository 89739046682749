<template>
    <div>
      <v-btn
        block
        dark
        class="google-login-btn py-7 rounded-lg"
        color="#4285F4"
        @click="handleGoogleLogin"
      >
        <slot>
          <span class="text-loginBtn">Login with Google</span>
          <v-icon right size="30" color="white">mdi-google</v-icon>
        </slot>
      </v-btn>
    </div>
  </template>
  
  <script>
  export default {
    name: "GoogleSignIn",
    props: {
      clientId: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.loadGoogleScript();
    },
    methods: {
      async loadGoogleScript() {
        if (!window.google) {
          let script = document.createElement("script");
          script.src = "https://accounts.google.com/gsi/client";
          script.async = true;
          script.defer = true;
          script.onload = () => console.log("Google Identity Services loaded.");
          script.onerror = () => console.error("Failed to load Google script.");
          document.head.appendChild(script);
        }
      },
  
      handleGoogleLogin() {
        if (window.google && window.google.accounts) {
          window.google.accounts.id.initialize({
            client_id: this.clientId,
            callback: this.onGoogleSignIn,
          });
  
          window.google.accounts.id.prompt();
        } else {
          console.error("Google Identity Services not loaded.");
        }
      },
  
      onGoogleSignIn(response) {
        console.log("Google Sign-In Token:", response.credential);
        this.$emit("success", response.credential);
      },
    },
  };
  </script>
  
  <style scoped>
  .google-login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-loginBtn {
    font-family: Lato;
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    margin-right: 10px;
  }
  </style>
  