<template>
  <v-dialog
    v-model="show"
    max-width="550px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar
        dark
        color="#7253CF"
        :height="$vuetify.breakpoint.smAndUp ? '103' : '100px'"
      >
        <div class="mb-n1">
          <v-img
            :width="$vuetify.breakpoint.smAndUp ? '130' : '70'"
            src="@/assets/publicDonationPage/parentSignUpModalLogo.png"
          ></v-img>
        </div>
        <v-spacer></v-spacer>
        <v-toolbar-title
          :class="
            $vuetify.breakpoint.xsOnly
              ? 'modalTitle1 d-flex justify-start pl-15'
              : 'modalTitle1'
          "
          ><div>{{ modalActionText.title }}</div></v-toolbar-title
        >
        <v-spacer
          v-if="$vuetify.breakpoint.smAndUp || signUpPageFlag"
        ></v-spacer>
        <v-btn class="align-self-baseline" icon @click="show = false">
          <v-icon size="32">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form v-if="signUpPageFlag" ref="parentSignUpForm" lazy-validation>
        <v-card-text style="">
          <v-row class="pb-5 pt-5" style="min-height: 346px">
            <v-col class="py-0 px-10">
              <v-row>
                <v-col cols="12" class="">
                  <span class="textField-label">Name</span>
                  <v-text-field
                    v-model="signUp.name"
                    outlined
                    dense
                    :disabled="signUp.loading"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n7">
                  <span class="textField-label">Email</span>
                  <v-text-field
                    v-model="signUp.email"
                    outlined
                    dense
                    :disabled="signUp.loading"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n7">
                  <span class="textField-label">Password</span>
                  <v-text-field
                    v-model="signUp.password"
                    type="password"
                    outlined
                    :disabled="signUp.loading"
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n5">
                  <v-btn
                    :loading="signUp.loading"
                    @click="onClickSignUpHandler()"
                    class="signUp-btn py-5"
                    depressed
                    width="100%"
                    dark
                    color="#CC0000"
                  >
                    <span>SIGN UP</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical style="border: 1px dashed #a3a3a3" v-if="hide && currentSubdomain === 'inmotion-elementry'">
            </v-divider>

            <v-col
              style="align-self: center"
              :class="$vuetify.breakpoint.smAndUp ? 'py-0 px-10' : 'px-10'"
             v-if="hide && currentSubdomain === 'inmotion-elementry'"
            >
              <div>
                <div
                  id="g_id_onload"
                  data-client_id="170558081662-355ok94bmohn6u4gm9bh47cvjob0k6rk.apps.googleusercontent.com"
                  data-context="signin"
                  data-ux_mode="popup"
                  data-callback="handleCredentialResponse"
                  data-auto_prompt="false"
                >
                  aaaa
                </div>

                <div
                  class="g_id_signin"
                  data-type="standard"
                  data-shape="rectangular"
                  data-theme="outline"
                  data-text="signin_with"
                  data-size="large"
                >
                  bbbb
                </div>
              </div>
               <GoogleSignIn
                  clientId="170558081662-355ok94bmohn6u4gm9bh47cvjob0k6rk.apps.googleusercontent.com"
                  @success="onGoogleLoginSuccess"
                />

              <v-btn
                @click="continueWithFacebookHandler()"
                depressed
                color="#1877F2"
                dark
                class="mt-6 socialMediaLogin py-5"
                width="100%"
              >
                <div class="" style="position: absolute; left: 0">
                  <v-img
                    width="25px"
                    src="@/assets/publicDonationPage/facebookLogin.svg"
                  ></v-img>
                </div>
                <span class="ml-5">Continue With Facebook</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-form v-else ref="parentLoginForm" lazy-validation>
        <v-card-text style="">
          <v-row class="pb-5 pt-5" style="min-height: 346px">
            <v-col class="py-0 px-10" style="align-self: center">
              <v-row>
                <v-col cols="12" class="">
                  <span class="textField-label">Email</span>
                  <v-text-field
                    v-model="login.email"
                    outlined
                    dense
                    :disabled="login.loading"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n7">
                  <span class="textField-label">Password</span>
                  <v-text-field
                    v-model="login.password"
                    type="password"
                    outlined
                    dense
                    :disabled="login.loading"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n5">
                  <v-btn
                    :loading="login.loading"
                    @click="onClickSignInHandler()"
                    class="signUp-btn py-5"
                    depressed
                    width="100%"
                    dark
                    color="#CC0000"
                  >
                    <span>SIGN IN</span>
                  </v-btn>
                  <div class="d-flex justify-end actionForgotPassword-text">
                    Forgot password?
                    <v-btn
                      :disabled="login.loading"
                      @click="toggleForgotPasswordModal()"
                      text
                      class="toggleForgotPassword-btn pa-0 ml-1"
                      >Reset</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical style="border: 1px dashed #a3a3a3" v-if="hide && currentSubdomain === 'inmotion-elementry'">
            </v-divider>
            <v-col
              style="align-self: center"
              :class="$vuetify.breakpoint.smAndUp ? 'py-0 px-10' : 'px-10'"
              v-if="hide && currentSubdomain === 'inmotion-elementry'"
            >
              <GoogleSignIn
                  clientId="170558081662-355ok94bmohn6u4gm9bh47cvjob0k6rk.apps.googleusercontent.com"
                  @success="onGoogleLoginSuccess"
                />
              <v-btn
                @click="continueWithFacebookHandler()"
                depressed
                color="#1877F2"
                dark
                class="mt-6 socialMediaLogin py-5"
                width="100%"
              >
                <div class="" style="position: absolute; left: 0">
                  <v-img
                    width="25px"
                    src="@/assets/publicDonationPage/facebookLogin.svg"
                  ></v-img>
                </div>
                <span class="ml-5">Continue With Facebook</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions
        class="pt-5 pb-7 d-flex justify-center actionSection-text"
      >
        {{ modalActionText.actionSub }}
        <v-btn @click="toggleForm()" text class="toggleForm-btn pa-0 ml-1">{{
          modalActionText.actionBtn
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {} from "@/constants/APIKeys";
import {} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
import authToken from "@/common/authToken";
import {
  API_COMMON_URL,
  API_MASTER_SOCIAL_LOGIN,
  ROUTER_URL,
} from "@/constants/urls";
import Keys from "@/constants/keys";
import Axios from "@/api/BaseAxios";
import GoogleSignIn from "@/components/Utils/GoogleSignIn.vue";

export default {
  name: "ParentSignUpModal",
  components: { GoogleSignIn,},
  data() {
    return {
    currentSubdomain:"",
      clientId:
        "170558081662-355ok94bmohn6u4gm9bh47cvjob0k6rk.apps.googleusercontent.com",
      signUpPageFlag: false,
      modalActionText: {
        title: "Welcome back, Parent",
        actionSub: "Dont have an account? ",
        actionBtn: "SignUp",
      },
      rules: RULES,
      signUp: {
        loading: false,
        name: "",
        email: "",
        password: "",
      },
      login: {
        loading: false,
        email: "",
        password: "",
      },
      hide: true,
      lastupdateflag: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getParentSignUpModalShow",
      getterSignUpPageFlag: "publicDonationPage/getSignUpPageFlag",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        if (!value) this.clearModal();
        else {
          console.log("this.getterSignUpPageFlag: ", this.getterSignUpPageFlag);
          this.signUpPageFlag = this.getterSignUpPageFlag;
        }
        return this.toggleModal({ show: value });
      },
    },
    payDonationBtnDisabled() {
      let flag = true;
      if (!this.selectedSearch.name) flag = false;
      if (!this.amount) flag = false;
      return !flag;
    },
    finalDonationAmount() {
      if (this.checkbox)
        return (
          this.amount *
          ((100 + this.stripeGatewayPercentage) / 100)
        ).toFixed(2);
      else return this.amount;
    },
  },
  watch: {
    getterSignUpPageFlag(value) {
      if (value) {
        this.signUpPageFlag = this.getterSignUpPageFlag;
        if (this.signUpPageFlag) {
          this.modalActionText.title = "Parent Sign up";
          this.modalActionText.actionSub = "Already have an account? ";
          this.modalActionText.actionBtn = "Login";
        } else {
          this.modalActionText.title = "Welcome back, Parent";
          this.modalActionText.actionSub = "Dont have an account? ";
          this.modalActionText.actionBtn = "Signup";
        }
      }
    },
  },
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleParentSignUpModal",
      updateUserDetails: "publicDonationPage/updateUserDetails",
      toggleParentVerifyEmailModal:
        "publicDonationPage/toggleParentVerifyEmailModal",
      toggleResetPasswordModal: "publicDonationPage/toggleResetPasswordModal",
      showToast: "snackBar/showToast",
    }),
    clearModal() {
      this.signUpPageFlag = false;
      this.modalActionText.title = "Welcome back, Parent";
      this.modalActionText.actionSub = "Dont have an account?  ";
      this.modalActionText.actionBtn = "SignUp";
      this.signUp.name = "";
      this.signUp.email = "";
      this.signUp.password = "";
      this.signUp.loading = false;
      this.login.email = "";
      this.login.password = "";
      this.login.loading = false;
    },
    toggleForm() {
      this.signUpPageFlag = !this.signUpPageFlag;
      if (this.signUpPageFlag) {
        this.modalActionText.title = "Parent Sign up";
        this.modalActionText.actionSub = "Already have an account? ";
        this.modalActionText.actionBtn = "Login";
      } else {
        this.modalActionText.title = "Welcome back, Parent";
        this.modalActionText.actionSub = "Dont have an account? ";
        this.modalActionText.actionBtn = "Signup";
      }
    },
    onClickSignInHandler() {
      if (this.$refs.parentLoginForm.validate()) {
        const _this = this;
        _this.login.loading = true;
        /**
         * Success handler of API call
         */
        const successHandler = (res) => {
          let data = res.data;
          console.log("success ", data);
          try {
            // Save token in local storage
            localStorage.setItem("token", res.headers.token);
          } catch (e) {
            console.log(e);
          }
          /**
           * Redirect to specific user panel
           */
          if (authToken.loginForFirstTime()) {
            this.updateUserDetails({ user: authToken.decodedToken() });
            /** user not logging in for the first **/
            _this.showToast({ message: "Logged In", color: "s" });
            this.$router.push({
              name: ROUTER_URL.publicParentDashboard.name,
            });
            this.show = false;
          } else {
            /** user logging in for the first time - show verify email modal **/
            _this.toggleParentVerifyEmailModalHandler(_this.login.email);
          }
          console.log(authToken.decodedToken(), "DECODE");
          _this.login.loading = false;
        };
        /**
         * Failure handler of API call
         */
        const failureHandler = (res) => {
          console.log("Failure one ", res, res.data.is_verification_done);
          if (res.data.is_verification_done === false) {
            console.log(res.data.message);
            _this.showToast({ message: res.data.message, color: "e" });
            _this.toggleParentVerifyEmailModalHandler(res.data.user_email);
          }
          _this.login.loading = false;
        };
        let formData = {};
        formData[Keys.USER_EMAIL] = this.login.email.trim();
        formData[Keys.PASSWORD] = this.login.password;

        /**
         * API Call for login
         */
        Axios.request_POST(
          API_COMMON_URL.login,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          false
        );
      }
    },
    onClickSignUpHandler() {
      if (this.$refs.parentSignUpForm.validate()) {
        /**
         * Success handler of API call
         */
        this.signUp.loading = true;
        const successHandler = (res) => {
          let data = res.data;
          console.log("success ", data);
          this.toggleModal({ show: false });
          if (data.is_verification_done) {
            console.log(
              "is_verification_done TRUE: ",
              data.is_verification_done
            );
            this.updateUserDetails({ user: authToken.decodedToken() });
            this.showToast({ message: "Logged In", color: "s" });
            this.$router.push({
              name: ROUTER_URL.publicParentDashboard.name,
            });
            setTimeout(() => {
              window.location.reload();
            }, 300); // Adds a small delay to ensure the navigation happens first.
            // try {
            //   // Save token in local storage
            //   localStorage.setItem("token", res.headers.token);
            // } catch (e) {
            //   console.log(e);
            // }
            // console.log(authToken.decodedToken(), "DECODE");
          } else {
            console.log(
              "is_verification_done FALSE: ",
              data.is_verification_done
            );
            console.log("message: ", data.message);
            this.toggleParentVerifyEmailModalHandler(this.signUp.email);
            localStorage.setItem("signupemail", this.signUp.email);
          }
          this.signUp.loading = false;

          // self.loginForm.submitting = false;
          /**
           * Redirect to specific user panel
           */
          // let userTeam = authToken.decodedToken().user_team
          // if(userTeam ==="teacher"){
          //   userTeam = authToken.decodedToken().user_type
          // }
          // if (authToken.loginForFirstTime() === true) {
          //   self.showToast({ message: 'Logged In', color: 's' })
          //   // Redirect to admin panel
          //   self.$router.push(
          //     Helper.getUserPanelUrl(userTeam)
          //   );
          // } else {
          //   self.showToast({ message: 'Set new password', color: 's' })
          //   self.$router.push({ name: ROUTER_URL.auth.children.resetPassword.name,
          //     query: { token: res.headers.token }})
          // }
        };
        /**
         * Failure handler of API call
         */
        const failureHandler = (res) => {
          console.log("Failure ", res);
          this.signUp.loading = false;
        };
        let formData = {};
        formData["user_type"] = "Parent";
        formData["user_name"] = this.signUp.name;
        formData[Keys.USER_EMAIL] = this.signUp.email.trim();
        formData[Keys.PASSWORD] = this.signUp.password;

        /**
         * API Call for login
         */
        Axios.request_POST(
          API_COMMON_URL.createUser,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          false
        );
      }
    },
    toggleParentVerifyEmailModalHandler(emailId) {
      let email = emailId.trim();
      this.show = false;
      console.log({ email });
      this.toggleParentVerifyEmailModal({ show: true, email });
    },
    loadGoogleScript() {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.accounts) {
          resolve();
        } else {
          const script = document.createElement("script");
          script.src = "https://accounts.google.com/gsi/client";
          script.async = true;
          script.defer = true;
          script.onload = resolve; // Resolve when script loads
          script.onerror = reject; // Reject if script fails
          document.head.appendChild(script);
        }
      });
    },
    initGoogleLogin() {
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id: this.clientId,
          callback: this.handleGoogleLogin, // Callback after successful login
        });
      } else {
        console.error("Google API script not loaded!");
      }
    },
    async continueWithGoogleHandler() {
      try {
        // Ensure Google API is loaded
        await this.loadGoogleScript();

        let gapi = window.gapi;
        let clientId =
          "170558081662-355ok94bmohn6u4gm9bh47cvjob0k6rk.apps.googleusercontent.com";
        let apiKey = "AIzaSyBpyW1Itjl_QtgV3E3FcWrboUidwbOO2wg";
        let discoveryDocs = [
          "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
        ];
        let scope = "https://www.googleapis.com/auth/drive.metadata.readonly";

        // Check if gapi is available
        if (!gapi) {
          console.error("gapi is not loaded correctly.");
          return;
        }

        // Load the client and auth2 libraries
        gapi.load("client:auth2", async () => {
          try {
            // Initialize Google API Client
            await gapi.client.init({
              apiKey,
              clientId,
              discoveryDocs,
              scope,
            });

            // Check if the user is already signed in
            if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
              this.googleUserProfile = gapi.auth2
                .getAuthInstance()
                .currentUser.get();
              this.loginApiCall(this.googleUserProfile);
              console.log("Logged in...");
            } else {
              // Sign in if not already signed in
              gapi.auth2
                .getAuthInstance()
                .signIn()
                .then(() => {
                  this.googleUserProfile = gapi.auth2
                    .getAuthInstance()
                    .currentUser.get();
                  this.loginApiCall(this.googleUserProfile);
                  console.log("NOT logged in...");
                })
                .catch((err) => {
                  console.error("Google sign-in error:", err);
                  alert(`Google auth error: ${err}`);
                });
            }
          } catch (err) {
            console.error("Google API init error:", err);
            alert(`Google API initialization error: ${err}`);
          }
        });
      } catch (err) {
        console.error("Error loading Google API script:", err);
        alert(`Error loading Google API: ${err}`);
      }
    },

    loginApiCall(data) {
      try {
        const idToken = data.credential; // Fix: Use data instead of response
        console.log("Google ID Token:", idToken);

        // Prepare request payload
        const formData = { token: 'eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc2M2Y3YzRjZDI2YTFlYjJiMWIzOWE4OGY0NDM0ZDFmNGQ5YTM2OGIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxNzA1NTgwODE2NjItMzU1b2s5NGJtb2huNnU0Z205Ymg0N2N2am9iMGs2cmsuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIxNzA1NTgwODE2NjItMzU1b2s5NGJtb2huNnU0Z205Ymg0N2N2am9iMGs2cmsuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMTQ0MjYwODg0OTU0NjAzMjA5MjYiLCJlbWFpbCI6InNvbmFsaWJob3N1cmVAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5iZiI6MTc0MDY0MTU3NiwibmFtZSI6IlNPTkFMSSBCSE9TVVJFIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0pjN3hJSVYyLS1CaENGczVhdk9TY0x1dkx6VWx3X05UWDlnX25BM2lJb2hvdGFGMlk9czk2LWMiLCJnaXZlbl9uYW1lIjoiU09OQUxJIiwiZmFtaWx5X25hbWUiOiJCSE9TVVJFIiwiaWF0IjoxNzQwNjQxODc2LCJleHAiOjE3NDA2NDU0NzYsImp0aSI6IjgwNjE5ZjM4MDVkMzZmOWU2N2Y4MmFiNGM0MDliYzgyNDNjZmM2YzkifQ.BtPRDFcYRRRG1ep9jls0WYZlxJpI94E84ekfGFkzsp-u9ZoTHJGScnrpjcOE13QglrQgb_3EiOrt-E-PMEIwEKennB5KJHDoTWNoRtYYq1OFJNhB29EY-UgRpG1ThSoGXCky8nmxK08jD0873lqI0l-yWe55FpmZEqEoM250g09jt-rY3voWTITndwFWtWnOx3gIeZKJTj2Ir_2f8QtMmM2vDHLfvjuELcnRahchoB10cQytLPZLSFDMQb69ECt9tO6umyOOeGmp2sOBJOrMFoOWRrex2KSjvtFaTpkPOroiKUlkeYMMCicPP-uBAOIORdXdJe9jjKWIkm2DlcQF9Q' };

        // Call API using Axios.request_POST
        Axios.request_POST(
          API_MASTER_SOCIAL_LOGIN, // API endpoint
          formData, // Data payload
          {}, // No extra headers
          this.successHandler, // Success callback
          this.failureHandler, // Failure callback
          false, // Loading state (if needed)
          false // Cache control (if needed)
        );
      } catch (error) {
        console.error("Google Login Error:", error);
      }
    },

    toggleForgotPasswordModal() {
      this.show = false;
      this.toggleResetPasswordModal({ show: true });
    },
    continueWithFacebookHandler() {
      this.loadFacebookSdk()
        .then(() => {
          window.FB.login(
            (response) => {
              if (response.authResponse) {
                console.log("Facebook login success:", response);
                this.loginApiCallFacebook(response.authResponse);
              } else {
                console.error("Facebook login failed:", response);
              }
            },
            { scope: "public_profile,email" }
          );
        })
        .catch((err) => {
          console.error("Facebook SDK error:", err);
        });
    },
    loginApiCallFacebook(authResponse) {
      const { accessToken, userID } = authResponse;
      const formData = {
        token: accessToken,
        userId: userID,
      };

      Axios.request_POST(
        API_MASTER_SOCIAL_LOGIN,
        formData,
        {},
        this.successHandler,
        this.failureHandler,
        false,
        false
      );
    },
    loadFacebookSdk() {
      return new Promise((resolve, reject) => {
        if (window.FB) {
          resolve();
        } else {
          const script = document.createElement("script");
          script.src = "https://connect.facebook.net/en_US/sdk.js";
          script.async = true;
          script.defer = true;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        }
      });
    },
    initFacebookLogin() {
      window.FB.init({
        appId:
          "974397307727-g7e63hm5d6qrqfrlse4lqkonn361122g.apps.googleusercontent.com", // Your actual App ID here
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });
    },
    sendGoogleToken(token) {
      const formData = {
        token: token, // GIS provides a JWT token, not accessToken
      };

      Axios.request_POST(
        API_MASTER_SOCIAL_LOGIN,
        formData,
        {},
        this.successHandler,
        this.failureHandler,
        false,
        false
      );
    },
  },
  mounted() {
 this.currentSubdomain = window.location.host.split(".")[0];
 //alert(this.currentSubdomain)
    window.handleCredentialResponse = (response) => {
      console.log("Google JWT Token:", response.credential);
      this.sendGoogleToken(response.credential);
    };
    this.initFacebookLogin()();
    this.loadGoogleScript()
      .then(() => {
        this.initGoogleLogin(); // Initialize only when Google API is ready
      })
      .catch((error) => {
        console.error("Google API failed to load:", error);
      });
    console.log("this.getterSignUpPageFlag: ", this.getterSignUpPageFlag);
    this.getSignUpPageFlag = this.getterSignUpPageFlag;
    this.lastupdateflag = true;
    localStorage.setItem("lastupdateflags", this.lastupdateflag);
  },
};
</script>

<style scoped>
.modalTitle1 {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 315%;
  color: #ffffff;
  margin-left: -62px;
}
.textField-label {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.61);
}
.signUp-btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
.actionSection-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.61);
}
.toggleForm-btn {
  text-transform: none;
  text-decoration-line: underline;
  color: blue;
  font-weight: 600;
  letter-spacing: normal;
  min-width: 0px !important;
  height: 0px !important;
  font-size: 17px;
}

.actionForgotPassword-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.61);
}
.toggleForgotPassword-btn {
  text-transform: none;
  text-decoration-line: underline;
  color: blue;
  font-weight: 600;
  letter-spacing: normal;
  min-width: 0px !important;
  height: 0px !important;
  font-size: 14px;
  margin-top: 13px;
}

.socialMediaLogin span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0px;
  text-transform: none;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 219%;
    color: #ffffff;
  }
  .textField-label {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: #2d2d2d;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
